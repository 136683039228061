<template>
  <v-app id="navigation" class="navigation">
      <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent fixed color="primary">
        <v-list-item class="px-2">
          <v-list-item-avatar rounded>
            <img alt="Vue logo" max-width="100%" max-height="100%"  src="../assets/logo.svg">
          </v-list-item-avatar>

          <v-list-item-title   class="whiteLetters" >Logichainge</v-list-item-title>

          <v-btn color="white" icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>


        <v-list>
          <v-list-item v-for="item in items" :key="item.title" link @click="$router.push({ path: item.router })">
            <v-list-item-icon>
              <v-icon color="white">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content >
              <v-list-item-title class="whiteLetters">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="$router.push('admin')" v-if="this.isAdmin">
            <v-list-item-icon>
              <v-icon color="white">mdi-shield-crown</v-icon>
            </v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title class="whiteLetters">Admin panel</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data () {
    return {
      drawer: true,
        items: [
          { title: 'Dashboard', router: 'home', icon: 'mdi-view-dashboard-outline' },
          { title: 'Orders', router: 'orderlist', icon: 'mdi-format-list-bulleted' },
          { title: 'Log out', router: 'logout', icon: 'mdi-logout' },
        ],
        mini: true,
        isAdmin: false,
    }
  },
  mounted() {
    this.$local.get('/users/me', {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token') 
      }
    }).then((res) => {
      console.log("USERS/ME RESULTS: ", res.data)
      if(res.data.is_admin) {
        this.isAdmin = true;
      }
    })
  }
};
</script>