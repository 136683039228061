<template>
    <v-app class="order-list-progress">
        <v-row>
            <v-col sm="1" md="1" lg="1">
                <Navigation />
            </v-col>
            <v-col class="joghurt" sm="10" md="10" lg="11">
                <v-row>
                    <v-col>
                        <div class="dragonFruit">
                            ORDERS
                        </div>
                    </v-col>
                </v-row>
                <div>
                    <div class="tabs">
                        <div class="tab" @click="clickPending">
                            Pending
                        </div>
                        <div class="tab-focused">
                            In progress
                        </div>
                        <div class="tab" @click="clickWip">
                            Clustering
                        </div>
                        <div class="tab" @click="clickWip">
                            Allocating
                        </div>
                        <div class="tab" @click="clickWip">
                            Tracking
                        </div>
                        <div class="tab" @click="clickWip">
                            Completed
                        </div>
                    </div>
                    <readyForPlanningTableManual />
                </div>
            </v-col>
        </v-row>
    </v-app>
</template>
<script>
import Navigation from "../components/Navigation";
import readyForPlanningTableManual from "../components/OrderViewComponents/readyForPlanningTableManual.vue";
export default {
    name: "OrderList",
    components: {
        Navigation,
        readyForPlanningTableManual
    },
    created() {
        console.log("LOCAL STORAGE TOKEN: ", localStorage.getItem('token'))
        if (!localStorage.getItem('token')) {
            this.$router.push('/');
        }
    },
    methods: {
        clickWip() {
            alert("This tab is not yet implemented");
        },
        clickPending() {
            this.$router.push('orderlist');
        }

    },
};
</script>