const state = {
    transportData: []
};

const getters = {
    allTransportData: (state) => state.transportData
};

const actions = {
    async fetchTransportData({ commit }, data) {

        commit('setTransportData', data);
        //console.log(data);
    }
};

const mutations = {
    setTransportData: (state, transportData) => (state.transportData = transportData)
};

export default {
    state,
    getters,
    actions,
    mutations
};