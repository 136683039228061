import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);




export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary:'#1E2B50', // '#0052FF', // #E53935
        secondary: '#0052FF',//'#c2c1c2', // #FFCDD2
        accent: '#f2ece3', // #3F51B5
        white: '#f1f1f1',
        background: '#000000',
        black: '#313135',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
})
