import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import OrderList from '../views/OrderList.vue'
import OrderListProgress from '../views/OrderListProgress'
import CompanyList from '../views/CompanyList.vue'
import Settings from '../views/Settings.vue'
import InProgress from '../views/InProgress.vue'
import Notifications from '../views/Notifications'
import ImageViewer  from '../views/ImageViewer'
import Logout from '../views/Logout'
import AdminPanel from '../views/AdminPanel'

Vue.use(VueRouter)



const routes = [{
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            auth: true
        }
    },
    {
        path: '/orderlist',
        name: 'OrderList',
        component: OrderList,
        meta: {
            auth: true
        }
    },
    {
        path: '/orderlist-progress',
        name: 'OrderListProgress',
        component: OrderListProgress,
        meta: {
            auth: true
        }
    },
    {
        path: '/progress',
        name: 'InProgress',
        component: InProgress,
        meta: {
            auth: true
        }
    },
    {
        path: '/companylist',
        name: 'CompanyList',
        component: CompanyList
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications
    },
    {
        path: '*',
        name: 'Not Found',
        component: NotFound
    },
    {
        path: '/image',
        name: 'Image',
        component: ImageViewer
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/admin',
        name: 'Admin',
        component: AdminPanel
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router