<template>
  <section class="section-container">
    <v-row class="signin">
      <v-col cols="8" class="left"> </v-col>
      <v-col cols="4" class="right">
        <v-col md="10" offset-md="1" class="textLogo">
          <img src="../assets/logoWSlogan.svg" alt="Logichainge name, words only" />
        </v-col>
        <div class="temp-div">
          <label for="email">Email</label>
          <input type="email" id="email">
          <label for="pass">Password</label>
          <input type="password" id="pass" @keyup.enter="login">
          <v-btn color="primary" dark @click="login">
            Login
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>
export default {
  name: "App",
  methods: {
    async login() {
      let mail = document.getElementById("email").value;
      let password = document.getElementById("pass").value;
      let data = new FormData();
      data.append('username', mail);
      data.append('password', password);
      this.$local.post('/token', data, { 'Content-Type': 'multipart/form-data' }).then((res) => {
        if (res.data.access_token) {
          localStorage.setItem('token', res.data.access_token);
          this.$router.push("home");
        }
        else {
          window.alert("Wrong credentials")
        }
      })
    },
  },
};
</script>
