<template>
  <v-app>
    <v-row>
      <v-col class="navbarColumnResize">
        <Navigation />
      </v-col>
      <v-col class="full-container">
        <!-- <h1>Companies</h1>
        <div class="main-body">
          <div class="filter-bar">
            <v-select :items="filters" label="Sort by" solo></v-select>
            <div class="search-bar">
              <v-icon>mdi-magnify</v-icon>
              <input type="text" v-model="search" placeholder="Search">
            </div>
          </div>
          <div class="companies-container">
            <div class="company company-header">
              <img src="../assets/company_avatar.png" class="hidden">
              <h4>Company</h4>
              <h4>Company ID</h4>
              <h4>Location</h4>
            </div>
          </div>
          <div v-for="company in companies" v-bind:key="company.IDClient" class="companies-container">

            <div class="company">
              <img src="../assets/company_avatar.png">
              <h3>{{ company.CompanyName }}</h3>
              <h4>{{ company.IDClient }}</h4>
              <h4>{{ company.Location }}</h4>
            </div>
          </div>
        </div> -->
        <h1>Under construnction</h1>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Navigation from "../components/Navigation";
import json from "../db/client_contacts.json";
export default {
  name: "InProgress",
  components: {
    Navigation
  },
  created() {
    console.log("LOCAL STORAGE TOKEN: ", localStorage.getItem('token'))
    if(!localStorage.getItem('token')){
      this.$router.push('/');
    }
  },
  data() {
    return {
      info: null,
      companies: json,
      search: '',
      filters: ["Name", "Location"]
    }
  },
  // computed: {
  //   fitleredList() {
  //     return this.companies.filter(post => {
    
  //   post.title.toLowerCase().includes(this.search.toLoweCase())
  //     })
  //   }
  // }
};
</script>

<style scoped lang="scss">
h1 {
  margin-top: 50px;
}

.main-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-bar {
  width: 100%;
  height: 70px;
  display: flex;
  align-self: center;
  background-color: white;
  box-shadow: 0px 4px 20px 2px #000000;
  margin-top: 45px;
  margin: 30px;
  align-items: center;
  .v-select {
    max-width: 200px;
    margin: 25px 0 0 30px;
    box-shadow: none;
  }

  .search-bar {
    margin: 30px;

    input {
      margin-left: 10px;
      border: none;
      transition: all 0.5s 0s ease-in-out;
    }

    input:focus {
      outline: none;
     
      border-bottom: 1px solid grey;
    }
  }
}

.companies-container {
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
  box-shadow: 0px 1px 9px 1px #000000;
}

.company {
  margin: 5px 0 5px 0;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  max-height: 100px;

  img {
    margin: 20px;
  }

  h3,
  h4 {
    width: 20%;
  }
}

.hidden {
  visibility: hidden;
}

.company-header {
  margin: 0;
  max-height: 40px;

  h4 {
    font-weight: 300;
  }
}
</style>
