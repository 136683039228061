<template>
  <v-app class="order-list">
    <v-row>
      <v-col sm="1" md="1" lg="1">
        <Navigation />
      </v-col>
      <v-col class="joghurt" sm="10" md="10" lg="11">
        <v-row>
          <v-col>
            <div class="dragonFruit">
              ORDERS
            </div>
          </v-col>
        </v-row>
        <div>
        <div class="tabs">
            <div class="tab-focused">
                Pending
            </div>
            <div class="tab" v-if="currentTab !== 'progress'" @click="clickProgress">
                In progress
            </div>
        </div>
        <pendingOrdersTableManual />
    </div>      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import Navigation from "../components/Navigation";
import pendingOrdersTableManual from "../components/OrderViewComponents/pendingOrdersTableManual.vue";

export default {
  name: "OrderList",
  components: {
    Navigation,
    pendingOrdersTableManual
  },
  created() {
    console.log("LOCAL STORAGE TOKEN: ", localStorage.getItem('token'))
    if (!localStorage.getItem('token')) {
      this.$router.push('/');
    }
  },
  methods: {
        clickWip() {
            alert("This tab is not yet implemented");
        },
        clickProgress() {
          this.$router.push("orderlist-progress")
        }

    },
};
</script>