<template>
    <div>
        <img :src="this.imageUrl" alt="">
    </div>
</template>
<script>
export default {
    data() {
        return {
            imageUrl: require('../assets/' + this.$route.query.img)
        }
    }
}
</script>