<template>
  <div class="main-container">
    <div class="loading-spinner-container" v-if="this.showSpinner">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <h3>Total orders: {{ this.allPendingOrders.length }}</h3>
   <div class="top-actions">
      <input type="text" placeholder="Search..." id="filterInput" class="searchBar" @keyup="filterList">
      <div class="upload-file-section">
        <span @click="deleteSelected" class="delete-button" v-if="anyFileSelected">DELETE
          SELECTED</span>
      </div>
    </div>
    <table id="orderTable">
      <tr class="table-header">
        <th class="selection"><input type="checkbox" class="selection-input" @click="checkAll"></th>
        <th>Date added</th>
        <th>Client ID</th>
        <th>Client Name</th>
        <th>Contact Person</th>
        <th>Order Ref</th>
        <th>Date First Address</th>
        <th>First Address</th>
        <th>Date Last Address</th>
        <th>Last Address</th>
        <th>MA</th>
        <th>Completion</th>
        <th>Actions</th>
      </tr>
      <tr v-for="pendingOrder in this.allPendingOrders" :key="pendingOrder.id">
        <td class="selection">
          <input type="checkbox" class="selection-input" v-model="pendingOrder.selected" @click="checkSelected">
        </td>
        <td @click="handleClick(pendingOrder)">{{ refactorDate(pendingOrder.created_at) }}</td>
        <td @click="handleClick(pendingOrder)">
          <span v-if="!pendingOrder.client_id">N/A</span>
          {{ pendingOrder.client_id }}
        </td>
        <td @click="handleClick(pendingOrder)">
          <span v-if="!pendingOrder.client.name[0]">N/A</span>{{ pendingOrder.client.name[0] }}
        </td>
        <td @click="handleClick(pendingOrder)">
          <span v-if="!pendingOrder.contact.email">N/A</span>{{ pendingOrder.contact.email[0] }}
        </td>
        <td @click="handleClick(pendingOrder)">
          <span v-if="!pendingOrder.display_number">N/A</span>{{ pendingOrder.display_number }}
        </td>
        <td @click="handleClick(pendingOrder)">
          <span v-if="!pendingOrder.activities[0].date[0]">N/A</span>
          <span v-if="pendingOrder.activities[0].date[0]">{{ pendingOrder.activities[0].date[0] }}</span>
        </td>
        <td @click="handleClick(pendingOrder)">
          <span v-if="!pendingOrder.activities[0].address.name[0]">N/A</span>
          <span v-if="pendingOrder.activities[0].address.name[0]">{{ pendingOrder.activities[0].address.name[0] }}</span>
        </td>
        <td @click="handleClick(pendingOrder)" v-if="pendingOrder.activities[1]">
          <span v-if="!pendingOrder.activities[1].date[0] || !pendingOrder.activities[1]">N/A</span>
          <span v-if="pendingOrder.activities[1].date[0] && pendingOrder.activities[1]">{{ pendingOrder.activities[1].date[0] }}</span>
        </td>
        <td @click="handleClick(pendingOrder)" v-if="!pendingOrder.activities[1]">
          <span>N/A</span>
        </td>
        <td @click="handleClick(pendingOrder)" v-if="pendingOrder.activities[1]">
          <span v-if="!pendingOrder.activities[1].address.name[0] || !pendingOrder.activities[1]">N/A</span>
          <span v-if="pendingOrder.activities[1].address.name[0] && pendingOrder.activities[1]">{{ pendingOrder.activities[1].address.name[0] }}</span>
        </td>
        <td @click="handleClick(pendingOrder)" v-if="!pendingOrder.activities[1]">
          <span>N/A</span>
        </td>
        <td @click="handleClick(pendingOrder)">
          <span v-if="pendingOrder.multi_activity != false && pendingOrder.multi_activity != true">N/A</span>{{
            pendingOrder.multi_activity
          }}
        </td>
        <td @click="handleClick(pendingOrder)">
          <span v-if="pendingOrder.certainty">{{ pendingOrder.certainty }}%</span>
          <span v-if="!pendingOrder.certainty">N/A</span>
        </td>
        <td>
          <span @click="deletePendingOrder(pendingOrder.id)" class="delete-button">DELETE</span>
        </td>
      </tr>
    </table>
  </div>
</template>
<style scoped>
h3 {
  margin: 20px 0 20px 0;
}

.main-container {
  position: relative;
}
tr:hover {
  transform: scale(1.003);
}
.table-header {
  background-color: var(--brand-color);
  color: white;
  /* display: inherit !important; */
}

.searchBar {
  transition: all 0.2s 0s ease-in-out;
}

.searchBar:focus {
  border: none;
}
.selection {
  width: 40px;
  padding-left: 20px;
}

.selection-input {
  width: 20px;
  height: 20px;
}
.upload-file-section {
  display: flex;
  align-items: center;
  width: 380px;
  justify-content: flex-end;
}
.top-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;

}
</style>
<script>
export default {
  data() {
    return {
      allPendingOrders: [],
      filteredPendingOrders: [],
      showSpinner: true,
      anyFileSelected: false,
      checkAllTf: false
    };
  },
  mounted() {
    this.getAllPendingOrders();
    this.filteredPendingOrders = this.allPendingOrders;
  },
  methods: {
    getAllPendingOrders() {
      this.allPendingOrders = [];
      this.$local
        .get("/transport_files/get_by_status/progress")
        .then((res) => {

          this.showSpinner = false;
          this.allPendingOrders = res.data;
          console.log("Ready for planning orders" , this.allPendingOrders)
          this.getActivities()
        })
        .catch((err) => {
          console.log(err);
        })
    },
    appendSelection() {
      this.allPendingOrders.forEach(tf => {
        this.$set(tf, "selected", false);
      })
    },
    checkSelected() {
      setTimeout(() => {
        this.anyFileSelected = false;
        this.allPendingOrders.forEach(tf => {
          if (tf.selected) {
            this.anyFileSelected = true;
          }
        })
      }, 100);

    },
    deleteSelected() {
      this.allPendingOrders.forEach(tf => {
        if (tf.selected) {
          this.$local.delete("/transport_files/" + tf.id)
            .then((res) => {
              console.log(res)
            })
            .catch((err) => {
              console.log(err);
            })
        }
      })
      setTimeout(() => {
      }, 2000);
      window.location.reload();
    },
    checkAll() {
      this.checkAllTf = !this.checkAllTf
      this.allPendingOrders.forEach(tf => {
        if (this.checkAllTf == true) {
          tf.selected = true;
          this.anyFileSelected = true;
        }
        else {
          tf.selected = false;
          this.anyFileSelected = false;
        }
      })
    },
    getActivities() {
      console.log("entering activities");
      this.allPendingOrders.forEach(transport_file => {
        this.$local
        .get("/transport_files/"+transport_file.id+"/activities")
        .then((res) => {

          this.showSpinner = false;
          this.allActtivities = res.data.activities;
          this.$set(transport_file, "activities", res.data)
          console.log("activities" , res.data)

        })
        .catch((err) => {
          console.log(err);
        })
        console.log('TRANSPORT FILES: ', transport_file)
      });
    },
    deletePendingOrder(id) {
      if (confirm("Are you sure you want to delete this transport file?")) {
        this.$local.delete("/transport_files/" + id)
        .then((res) => {
          console.log(res)
          window.location.reload();
        })
        .catch((err) => {
        console.log(err);
        })
        
      }
    },
    refactorDate(date) {
      var dateObject = new Date(date);
      return dateObject.toLocaleDateString("nl-NL");
    },
    handleClick(value) {
      this.$router.push({
        name: "InProgress",
        params: { transport_order_id: value.id },
      });
      console.log(value.id);
    },
    filterList() {
      let input, filter, table, tr, td, txtValue;

      input = document.getElementById("filterInput");
      filter = input.value.toUpperCase();
      table = document.getElementById("orderTable");
      tr = table.getElementsByTagName("tr");

      for (let i = 1; i < tr.length; i++) {
        td = tr[i];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          }
          else {
              tr[i].style.display = "none";
          }
        }
      }
    }
  },
};
</script>
