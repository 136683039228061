<template>
  <v-app class="not-found">
    <Navigation />

    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h1>Oops!</h1>
          <h2>404 - The Page can't be found</h2>
        </div>
        <v-btn
          class="signin-btn"
          type="submit"
          rounded
          color="#cfb997"
          dark
          @click="$router.push('home')"
        >
          Go To Dashboard
        </v-btn>
      </div>
    </div>
  </v-app>
</template>

<script>
import Navigation from "../components/Navigation";
export default {
  name: "NotFound",
  components: {
    Navigation,
  },
};
</script>