<template>
  <v-app class="settings">
    <Navigation />
    <h1>Settings</h1>
    <div class="main-body">
      <div class="body-info">
        <img src="../assets/user.png" alt="user avatar">
        <div class="text-fields">
          <div class="inputs">
            <label for="name">Name</label>
            <input type="text" id="name">
          </div>
          <div class="inputs">
            <label for="company">Company</label>
            <input type="text" id="company">
          </div>
          <div class="inputs">
            <label for="email">E-mail</label>
            <input type="email" id="email">
          </div>
        </div>
      </div>
      <div class="fields">
        <h2>Fields</h2>

        <v-row class="mt-3">
          <v-col>
            <v-switch v-model="ex11" label="File Type" color="primary" value="File type" hide-details></v-switch>
            <v-switch v-model="ex11" label="Main Modality" color="primary" value="Main modality" hide-details></v-switch>
            <v-switch v-model="ex11" label="Equipment Type" color="primary" value="Equipment type"
              hide-details></v-switch>
            <v-switch v-model="ex11" label="Service Level" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Address" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Postal Code" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="City" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Country" color="primary" value="primary" hide-details></v-switch>
          </v-col>
          <v-col>
            <v-switch v-model="ex11" label="Unit" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Unit Description" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Description of Goods" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Gross Weight" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Nett Weight" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Loading Meters" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Width" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Height" color="primary" value="primary" hide-details></v-switch>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>
</template>
<script>
import Navigation from "../components/Navigation";
export default {
  name: "InProgress",
  components: {
    Navigation
  },
  created() {
    console.log("LOCAL STORAGE TOKEN: ", localStorage.getItem('token'))
    if (!localStorage.getItem('token')) {
      this.$router.push('/');
    }
  },
};
</script>
