<template>
  <v-card
    class="mx-auto text-center"
  >
    <v-card-text>
      <v-sheet >
        <v-sparkline
          :value="value"
          padding="25"
          stroke-linecap="round"
          smooth
        >
          <template v-slot:label="item">
            €{{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
      
    </v-card-text>
      <div class="text-h6 font-weight-light mb-2">
        Sales Last 24
      </div>

    

    <v-card-actions class="justify-center">
      <v-btn class="redirect-btn" type="submit" rounded color="primary">
              Go to Report
            </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.v-card{
    height: 350px
}
.p {
    margin-top: 5%;
}
</style>
<script>
  export default {
    data: () => ({
      value: [
        423,
        446,
        675,
        510,
        590,
        610,
        760,
      ],
    }),
  }
</script>