<template>
    <h1>You are being logged out</h1>
</template>
<script>


export default {
  name: "Logout",
  components: {
  },
  created() {
    console.log('Logging out');
    localStorage.removeItem('token');
    this.$router.push('/');
  }
};
</script>