<template>
  <v-card
    class="mx-auto"
  >
    <v-sheet>
      <v-sparkline
        :labels="labels"
        :value="value"
        padding="16"
        stroke-linecap="round"
        smooth
      ></v-sparkline>
    </v-sheet>
<v-spacer></v-spacer>
    <v-card-text>
      <div class="text-h6 font-weight-light mb-2">
        Active Users
      </div>

      <v-divider class="my-2"></v-divider>
      <v-icon
        class="mr-2"
        small
      >
        mdi-clock
      </v-icon>
      <span class="text-caption grey--text font-weight-light">Updated 26 minutes ago</span>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-card{
    height: 350px
}
</style>
<script>
  export default {
    data: () => ({
      labels: [
        '9am',
        '11am',
        '13pm',
        '15pm',
        '17pm',
        '19pm',
      ],
      value: [
        75,
        82,
        64,
        89,
        64,
        12,
      ],
    }),
  }
</script>