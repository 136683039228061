<template>
  <v-app class="home">
    <v-row>
      <v-col class="navbarColumnResize">
        <Navigation />
      </v-col>
      <v-col cols="12" sm="11" md="11" lg="11">

        <v-row class="cherryBlossom">
          <v-col sm="1" md="3" lg="4">
            <SalesChart />
          </v-col>
          <v-col sm="1" md="3" lg="4">
            <ActiveUsers />
          </v-col>
        </v-row>
        <v-row>
          <v-col z-index="100">
            <orderNavigatorManual />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Navigation from "../components/Navigation";
import SalesChart from "../components/salesChart.vue";
import orderNavigatorManual from "../components/orderNavigatorManual.vue";
import ActiveUsers from "../components/ActiveUsers.vue"

export default {
  name: "Home",
  components: {
    Navigation,
    SalesChart,
    ActiveUsers,
    orderNavigatorManual
  },
  created() {
    console.log("LOCAL STORAGE TOKEN: ", localStorage.getItem('token'))
    if(!localStorage.getItem('token')){
      this.$router.push('/');
    }
  },
  mounted() {
    console.log("TOKEN", this.$token.token);
    if(this.$token.token == "") {
      this.$router.push('/');
    }
  },
};
</script>