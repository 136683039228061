<template>
    <div class="in-progress">
      <Navigation />
      <v-btn color="primary" class="newTabButton bringBackButton" dark @click="bringImageBack" v-if="isNewTabOpen">
        Bring image back
      </v-btn>
      <FileInfoCardExtended v-if="isNewTabOpen" />
      <v-container fluid>
        <v-row cols="12">
          <v-col sm="1" md="1" lg="1" no-gutters> </v-col>
          <v-col sm="6" md="5" lg="5">
            <fileInfoCardManual v-if="!isNewTabOpen" />
          </v-col>
          <v-spacer></v-spacer>

          <v-col sm="6" md="6" lg="6">
            <v-btn color="primary" class="newTabButton" dark @click="openInNewTab" v-if="!isNewTabOpen">
              Open image in new tab
            </v-btn>
            <div class="img-section">
              <div v-for="url in this.imageUrls" :key="url">
                <v-img :src="url" class="invoice" v-if="!isNewTabOpen" max-height="99vh" contain></v-img>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import Navigation from "../components/Navigation";
import fileInfoCardManual from "../components/InProgressComponents/fileInfoCardManual.vue";
import FileInfoCardExtended from "../components/InProgressComponents/FileInfoCardExtended.vue";
export default {
  name: "InProgress",
  components: {
    Navigation,
    fileInfoCardManual,
    FileInfoCardExtended
  },
  data() {
    return {
      isNewTabOpen: false,
      transport_order_id: "",
      imageUrls: ""
    }
  },
  mounted() {
    if (this.$route.params.transport_order_id == null) {
      console.log("error loading data");
      this.$router.push("orderlist");
    } else {
      this.transport_order_id = this.$route.params.transport_order_id;
      this.getTransportData();
      console.log(this.$route.params.transport_order_id);
    }
  },
  methods: {
    getTransportData() {
      this.transportData = {};
      this.$local
        .get("/transport_files/" + this.transport_order_id)
        .then((res) => {
          this.transportData = res.data;
          this.imageUrls = this.transportData.reference;
          console.log("Image urls", this.transportData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openInNewTab() {
      this.isNewTabOpen = true;

      let w = window.open("", '_blank', 'location=yes,height=900,width=1100,scrollbars=yes,status=yes');
      this.imageUrls.forEach(img => {
        w.document.body.innerHTML += "<img src='" + img + "'>";
      });
      let css = document.createElement('style');
      css.type = 'text/css';
      css.innerText = 'img {width: 100%; max-width: 1000px}';
      w.document.head.appendChild(css);
    },
    bringImageBack() {
      this.isNewTabOpen = false;
    },

  }

};
</script>