<template>
    <div class="admin-panel">
        <Navigation />
        <div class="content">
            <h1>Admin panel</h1>
            <div class="users-list">
                <h2>Users list</h2>
                <div v-for="user in this.allUsers" :key="user" class="user-entry">
                    <h4>Name: <span>{{ user.full_name }}</span></h4>
                    <h4>Email: <span>{{ user.email }}</span></h4>
                    <h4>Username: <span>{{ user.username }}</span></h4>
                    <h4>Admin: <span>{{ user.is_admin }}</span></h4>
                    <span class="delete" @click="deleteUser(user.id)">Delete</span>
                </div>
            </div>
            <div class="add-user-section">
                <h2>Add user</h2>
                <label for="name">Full name</label>
                <input type="text" id="name">
                <label for="email">Email</label>
                <input type="text" id="email">
                <label for="username">Username (used for login)</label>
                <input type="text" id="username">
                <label for="pass">Password</label>
                <input type="text" id="pass">
                <v-btn color="primary" dark @click="createUser">
                    Create user
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import Navigation from "../components/Navigation";

export default {
    name: 'Admin',
    components: {
        Navigation
    },
    data() {
        return {
            allUsers: []
        }
    },
    mounted() {
        this.$local.get('/users/me', {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            console.log("USERS/ME RESULTS: ", res.data)
            if (!res.data.is_admin) {
                this.$router.push('home')
            }
        })
        this.getAllUsers();
    },
    methods: {
        getAllUsers() {
            this.$local.get('/users/').then((res) => {
                this.allUsers = res.data;
                console.log(this.allUsers)
            })
        },
        getInputValue(id) {
            if (document.getElementById(id).value == "" || !document.getElementById(id).value) {
                return 'N/A';
            }
            else return document.getElementById(id).value;
        },
        createUser() {
            let name = this.getInputValue('name');
            let email = this.getInputValue('email');
            let username = this.getInputValue('username');
            let pass = this.getInputValue('pass');

            let submitObj = {
                "username": username,
                "email": email,
                "disabled": false,
                "full_name": name,
                "password": pass
            }

            this.$local.post('/users/', submitObj, {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }).then((res) => {
                console.log(res);
                this.$router.go();
            })
        },
        deleteUser(id) {
            this.$local.delete('/users/' + id, {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }).then((res) => {
                console.log(res);
                this.$router.go();
            })
        }
    }
}
</script>