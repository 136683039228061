<template>
    <div class="order-navigator">
        <div class="tabs">
            <div class="tab-focused" v-if="currentTab == 'pending'" @click="clickPending">
                Pending
            </div>
            <div class="tab" v-if="currentTab !== 'pending'" @click="clickPending">
                Pending
            </div>
            <div class="tab-focused" v-if="currentTab == 'progress'" @click="clickProgress">
                In progress
            </div>
            <div class="tab" v-if="currentTab !== 'progress'" @click="clickProgress">
                In progress
            </div>
        </div>
        <pendingOrdersTableManual v-if="currentTab == 'pending'"/>
        <readyForPlanningTableManual v-if="currentTab == 'progress'"/>
    </div>
</template>
<script>
import pendingOrdersTableManual from './OrderViewComponents/pendingOrdersTableManual.vue';
import readyForPlanningTableManual from './OrderViewComponents/readyForPlanningTableManual.vue';
export default {
    data() {
        return {
            currentTab: 'pending'
        }
    },
    components: {
        pendingOrdersTableManual,
        readyForPlanningTableManual
    },
    methods: {
        clickPending() {
            this.currentTab = 'pending';
        },
        clickProgress() {
            this.currentTab = 'progress';
        },
        clickWip() {
            alert("This tab is not yet implemented");
        }

    },

};
</script>